import axios from 'axios'
const _api = axios.create({
//   baseURL: 'http://localhost:3000',
  baseURL: 'https://robux-supply-api.rbxadder.com',
  timeout: 10000,
})

export const setToken = (token) => {
    _api.defaults.headers.common = {
        "Authorization": `bearer ${token}`
    };
}

setToken(localStorage.getItem("v-token"))


let Api = {};

Api.login = async (username, password) => {
    let result = await _api.post('/auth/login', {username, password});
    return result.data;
}


Api.getUser = async () => {
    let result = await _api.get('/users');
    return result.data;
}

Api.getCookies = async () => {
    let result = await _api.get('/cookies');
    return result.data;
}

Api.getCookieSpending = async (id) => {
    let result = await _api.get(`/cookies/${id}/spending`);
    return result.data;
}

Api.addCookie = async (value) => {
    let result = await _api.post('/cookies', {value});
    return result.data;
}

Api.deleteCookie = async (id) => {
    let result = await _api.get(`/cookies/${id}/delete`);
    return result.data;
}

Api.getPayments = async () => {
    let result = await _api.get('/payments');
    return result.data;
}

export default Api;

