<template>
  <div
    class="mt-8"
  >
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Payments</h1>
       <v-spacer></v-spacer>

    </v-row>
    <v-data-table
        dense
        :headers="headers"
        :items="this.payments"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    ></v-data-table>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      loading: true,
      headers: [
        { text: 'ID', value: '_id' },
        { text: 'Amount', value: 'amount' },
        { text: 'Notes', value: 'notes' },
        { text: 'Date', value: 'createdAt' },
      ],
    }),
    mounted() {
      this.getPayments()
    },
    computed: {
      ...mapGetters([
        'payments',
      ])
    },
    methods: {
      async getPayments () {
        await this.$store.dispatch("getPayments");
        this.loading = false
      },
    }
  }
</script>