import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home - RbxBoost'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: 'LogIn - RbxBoost'
    }
  },
  { 
    path: '*', 
    component: NotFound,
    meta: {
      title: 'Not Found - RbxBoost'
    }
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.is_authenticated ) next({ name: 'Login' })
  if (to.name === 'Login' && store.getters.is_authenticated ) next({ name: 'Home' })
  else next()
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta.title;
  });
});


export default router
