<template>
  <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat 
            class="py-12 text-center flex-column justify-space-around"
          >
            <div class="display-3 mt-5">Page not found.</div>
            <div class="grey--text lighten-5">
              The page you are trying to get to never existed in this reality,
              or has migrated to a parallel universe.
            </div>
            
            <v-actions>
              <v-btn
                color="primary"
                @click="goBack()"
              >
                GO BACK TO HOME
              </v-btn>
            </v-actions>
          </v-card>
        </v-flex>
      </v-layout>
  </v-container>

</template>

<script>

  export default {
    name: 'NotFound',
    methods: {
      goBack() {
        this.$router.push({name: "Home"})
      }
    }
  }
</script>

<style>
  .paragraph-text {
    font-size: 18px;
  }
</style>