<template>
  <v-container>
    <v-row
      class="my-4"
    >
      <v-col
        cols="4"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Unpaid Earnings
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.unpaid_earning }} $</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            >
              <v-icon
                size="80"
              >
                mdi-briefcase-clock
              </v-icon>            
            </v-list-item-avatar>
            
          </v-list-item>
        </v-card>

      </v-col>
      <v-col
        cols="4"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Total Earnings
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.total_earning}} $</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            >
              <v-icon
                size="80"
              >
                mdi-briefcase
              </v-icon>            
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
        
      </v-col>
      <v-col
        cols="4"
      >
        <v-card
          elevation="2"
          :loading="this.loading"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                Total R$ Sent
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-h5"
              >{{this.user.total_rbx_sent}} rbx</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            >
              <v-icon
                size="80"
              >
                mdi-currency-usd
              </v-icon>      
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
        
      </v-col>
    </v-row>

    <div 
      class="small my-8"
      v-if="datacollection"
    >
      <h1
        class="text-xl mb-8"
       >Daily Robux Spent</h1>
      <line-chart
        :chart-data="datacollection"
        style="height: 400px"
        :options="datacollection.options"
       ></line-chart>
    </div>

    <CookiesTable></CookiesTable>
    <PaymentTable></PaymentTable>
  </v-container>

</template>

<script>
  
  import CookiesTable from "../components/CookiesTable.vue";
  import PaymentTable from "../components/PaymentTable.vue";
  import LineChart from '../components/LineChart.js'

  import { mapGetters } from 'vuex'


  export default {
    name: 'Home',
    components: {
      CookiesTable,
      PaymentTable,
      LineChart
    },
    data: () => ({
      loading: true,
      datacollection: null  
    }),
    mounted() {
      this.getUser();

    },
    computed: {
      myStyles () {
        return {
          height: `20px`,
          position: 'relative',
          responsive: true
        }
      },
      ...mapGetters([
        'user',
      ])
    },
    methods: {
      async getUser() {
        await this.$store.dispatch("getUser");
        this.loading = false;
        this.fillData()
      },
      fillData () {
        this.datacollection = {
          labels: Object.keys(this.user.daily_rbx_sent),
          datasets: [
            {
              // label: 'Data One',
              // backgroundColor: "transparent",
              // borderColor: "aqua",
              data: Object.values(this.user.daily_rbx_sent)
            }
          ],
          options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }]
            },
            legend: {
              display: false //This will do the task
            },
            responsive: true,
            maintainAspectRatio: false,
            // height: "10"
          }
        }
      },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      }
    }
  }
</script>
