import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import Api, { setToken } from '../services/api'
import moment from 'moment';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    is_authenticated: localStorage.getItem("v-token") ? true : false,
    user: {},
    token: localStorage.getItem("v-token"),
    cookies: [],
    payments: [],
    error_message: "",
    auth_error_message: "",
    cookie_error_message: "",
  },
  getters: {
    is_authenticated: (state) => {
      return state.is_authenticated;
    },
    token: (state) => {
      return state.token;
    },
    user: (state) => {
      return state.user;
    },
    cookies: (state) => {
      return state.cookies;
    },
    payments: (state) => {
      return state.payments.map(x => {
        return {
          ...x,
          createdAt: moment(x.createdAt).fromNow()
        }
      });
    },
    error_message: (state) => {
      return state.error_message
    },
    auth_error_message: (state) => {
      return state.auth_error_message
    },
    cookie_error_message: (state) => {
      return state.cookie_error_message
    }
  },
  mutations: {
    setToken: (state, {access_token}) => {
      state.token = access_token
      state.is_authenticated = true
    },
    clearUser: (state) => {
      state.user = {};
      state.token = null;
      state.is_authenticated = false;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setCookies: (state, cookies) => {
      state.cookies = cookies;
    },
    setCookieSpending: (state, data) => {
      let arr = [...state.cookies];
      let index = arr.findIndex(x => x._id == data.id);
      if (index > -1) {
          arr[index] = {
              ...arr[index],
              ...data
          }
      }
      state.cookies = arr;
    },
    setPayments: (state, payments) => {
      state.payments = payments;
    },
    addCookie: (state, cookie) => {
      state.cookies.push(cookie)
    },
    deleteCookie: (state, id) => {
      let arr = [...state.cookies];
      let i = arr.findIndex(x => x._id == id);
      if (i > -1) {
        arr.splice(i, 1)
      }
      state.cookies = arr;
    },
    setError: (state, message) => {
      if (!state.error_message) {
        state.error_message = message;
      }
    },
    setAuthError: (state, message) => {
        state.auth_error_message = message;
    },
    setCookieError: (state, message) => {
        state.cookie_error_message = message;
    },
    clearError: (state) => {
      state.error_message = ""
    }
  },
  actions: {
    login: async (context, {username, password}) => {
      try {
        context.commit("setAuthError", "")
        let result = await Api.login(username, password);
        if (result.success) {
          localStorage.setItem("v-token", result.data.access_token)
          context.commit("setToken", result.data)
          setToken(result.data.access_token);
          router.push('/')
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          context.commit("setAuthError", error.response.data)
        } else {
          context.commit("setAuthError", "Something Went Wrong, Please Try Again Later")
        }
      }
    },
    logout: (context) => {
      localStorage.removeItem("v-token");
      context.commit("clearUser");
    },
    getUser: async (context) => {
      try {
        let result = await Api.getUser();
        if (result.success) {
          context.commit("setUser", result.data.user)
        }
      } catch (error) {
        context.commit("setError", "Something Went Wrong, Please Try Again Later")
      }

      return;
    },
    getCookies: async (context) => {
      try {
        let result = await Api.getCookies();
        if (result.success) {
          context.commit("setCookies", result.data.cookies)
        }
      } catch (error) {
        context.commit("setError", "Something Went Wrong, Please Try Again Later")
      }
      return
    },
    getCookieSpending: async (context, id) => {
      try {
        let result = await Api.getCookieSpending(id);
        if (result.success) {
          context.commit("setCookieSpending", {id, daily_rbx_sent: result.data.daily_rbx_sent})
        }
      } catch (error) {
        context.commit("setError", "Something Went Wrong, Please Try Again Later")
      }

      return;
    },
    deleteCookie: async (context, id) => {
      try {
        let result = await Api.deleteCookie(id);
        if (result.success) {
          context.commit("deleteCookie", id)
        }
      } catch (error) {
        context.commit("setError", "Something Went Wrong, Please Try Again Later")
      }

      return;
    },
    addCookie: async (context, value) => {
      try {
        context.commit("setCookieError", "")
        let result = await Api.addCookie(value);
        if (result.success) {
          context.commit("addCookie", result.data.cookie)
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          context.commit("setCookieError", error.response.data)
        } else {
          context.commit("setCookieError", "Something Went Wrong, Try Again Later")
        }
      }

      return;
    },
    getPayments: async (context) => {
      try {
        let result = await Api.getPayments();
        if (result.success) {
          context.commit("setPayments", result.data.payments)
        }
      } catch (error) {
        context.commit("setError", "Something Went Wrong, Please Try Again Later")
      }

      return;
    }
  },
  modules: {
  }
})
