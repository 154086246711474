<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h2
          class="h3"
        >
          RbxBoost
        </h2>
      </div>

      <v-spacer></v-spacer>

      <v-banner
        v-if="this.user && this.user.rate"
        class="pl-4 pr-4"
      >
        Rate: {{ this.user.rate }}$/K
      </v-banner>

      <v-btn
        v-if="is_authenticated"
        text
        @click="logout"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>

      <v-alert
        dense
        dismissible
        prominent
        type="error"
        ref="Alert"
        :value="false"
      >
        {{ errorMessage }}
        <template v-slot:close="{ toggle }">
          <v-icon
           @click="hideAlert(toggle)"
          >mdi-close-circle</v-icon>
        </template>
      </v-alert>
      <router-view :key="$route.path"/>
    </v-main>


  </v-app>
</template>

<script>

import {setToken} from './services/api'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {

},
  data: () => ({
    //
  }),
  mounted() {
    setToken(this.$store.getters.token)
  },
  computed: {
    errorMessage() {
      if (this.$store.getters.error_message) {
      this.showErrorAlert(this.$store.getters.error_message)
      }
      return this.$store.getters.error_message;
    },
    ...mapGetters([
        'user',
        'is_authenticated'
      ])
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout')
      this.$router.push('Login')
    },
    showErrorAlert () {
      if (this.$refs.Alert) {
        this.$refs.Alert.toggle()
      } 
    },
    hideAlert(toggle) {
      this.$store.commit("clearError", "");
      toggle()
    }
  }
};
</script>
