<template>
  <div>
    <v-row
        class="my-2"
    >
       <h1
        class="text-xl"
       >Cookies</h1>
       <v-spacer></v-spacer>

        <v-btn
            color="primary"
            elevation="2"
            large
            @click="addItem()"
        >
            Add Cookie
        </v-btn>
    </v-row>
    <v-data-table
        dense
        :headers="headers"
        :items="this.cookies"
        item-key="name"
        class="elevation-1"
        :loading="this.loading"
    >
        <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card 
                  class="px-8 py-4"
                >
                    <v-card-title class="text-h5 text-center">Are you sure you want to delete this cookie?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirm</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card
                class="px-4 py-4"
              >
                  <v-card-title class="text-h5">Add New Cookie</v-card-title>
                  <v-textarea
                        clearable
                    clear-icon="mdi-close-circle"
                    label="Cookie"
                    v-model="editedItem.value"
                    rows="12"

                  ></v-textarea>
                  <v-alert
                    v-if="cookie_error_message"
                    type="error"
                  >
                    <span>{{cookie_error_message}}</span>
                  </v-alert>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="blue " text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Save</v-btn> -->
                  <v-btn
                      color="default"
                      elevation="2"
                      @click="close()"
                      :disabled="addCookieLoading"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      elevation="2"
                      :disabled="!editedItem.value || addCookieLoading"
                      @click="addCookie()"
                  >
                    <v-progress-circular
                      center
                      size="20"
                      width="2"
                      color="primary"
                      indeterminate
                      v-if="addCookieLoading"
                    ></v-progress-circular>
                    <span
                      v-else
                    >Save</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-if="selectedValue" v-model="dialogShow" max-width="500px">
                <v-card
                  class="px-8 py-8"
                >
                    <p>{{ selectedValue}}</p>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeShow">Close</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
              <v-dialog v-if="dialogGraph" v-model="dialogGraph" max-width="90%">
               <v-card 
                class="small py-8 px-8"
              >
                <h1
                  class="text-xl mb-8 "
                >Daily Robux Spent</h1>

                <line-chart
                  v-if="datacollection"
                  :chart-data="datacollection"
                  style="height: 400px"
                  :options="datacollection.options"
                ></line-chart>

                <div
                    v-else
                    style="display: flex"
                  class="flex justify-center py-8"
                >
                  <v-progress-circular
                    center
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>

              </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              color="error"
              elevation="2"
              small
              @click="deleteItem(item)"
          >
              Remove
          </v-btn>
          <v-btn
              color="primary"
              elevation="2"
              class="ml-2 mt-2 mb-2"
              small
              @click="showItemGraph(item)"
          >
              Daily spent
          </v-btn>
        </template>

        <template v-slot:item.value="{ item }">
          <span
            style="cursor: pointer"
            @click="showItem(item.value)"
          >
            {{item.value.substring(0, 50) }}...
          </span>
        </template>
    </v-data-table>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import LineChart from "../components/LineChart.js";

  export default {
    components: {
      LineChart
    },
    data: () => ({
      loading: true,
      addCookieLoading: false,
      headers: [
        { text: 'ID', value: '_id' },
        { text: 'Cookie', value: 'value' },
        { text: 'Available Amount', value: 'available_amount' },
        { text: 'Total Rbx Spent', value: 'spent_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      defaultItem: {
        value: "",
      },
      editedItem: {},
      dialogDelete: false,
      dialog: false,
      dialogShow: false,
      dialogGraph: false,
      selectedItem: {},
      selectedValue: "",
      datacollection: null
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      ...mapGetters([
        'cookies',
        'cookie_error_message'
      ])
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogGraph (val) {
        val || this.closeGraph()
      },
    },

    created () {
      this.getCookies()
    },

    methods: {
      async getCookies () {
        this.loading = true;
        await this.$store.dispatch("getCookies");
        this.loading = false
      },
      async getCookieSpending() {
        await this.$store.dispatch("getCookieSpending", this.selectedItem._id);
        this.loading = false;
        let index = this.cookies.findIndex(x => x._id == this.selectedItem._id);
        if (index > -1) {
          this.selectedItem = this.cookies[index];
          this.fillData()
        }
      },
      async addCookie () {
        if (!this.editedItem.value) return;
        this.addCookieLoading = true;
        await this.$store.dispatch("addCookie", this.editedItem.value);
        this.addCookieLoading = false
        if (this.cookie_error_message == "") {
          this.dialog = false
        }
      },
      fillData () {
        this.datacollection = {
          labels: Object.keys(this.selectedItem.daily_rbx_sent),
          datasets: [
            {
 
              data: Object.values(this.selectedItem.daily_rbx_sent)
            }
          ],
          options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }]
            },
            legend: {
              display: false //This will do the task
            },
            responsive: true,
            maintainAspectRatio: false,
            // height: "10"
          }
        }
      },
      addItem() {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      showItem(item) {
        this.selectedValue = item;
        this.dialogShow = true
      },
      showItemGraph(item) {
        this.selectedItem = item;
        this.getCookieSpending();
        this.dialogGraph = true
      },
      async deleteItemConfirm () {
        await this.$store.dispatch("deleteCookie", this.editedItem._id);
        this.closeDelete()
      },


      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
      closeShow () {
        this.dialogShow = false
        this.$nextTick(() => {
          this.selectedValue = Object.assign({}, this.defaultItem)
        })
      },
      closeGraph () {
        this.dialogGraph = false
        this.$nextTick(() => {
          this.datacollection = null
        })
      },
    },
  }
</script>